import React from "react";

import LessonLayout from "~/layouts/lesson";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";

const Page = () => {
  return (
      <LessonLayout
        current_step={8}
        lesson={"Lesson 3"}
        color={"ms"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 8</H2>

          <p>Now that you have added criteria in the "Lesson 3 Persuasion and Relevance" category of your rubric, look again at the CBD websites and use this category of your rubric to score those sites. (Remember, you have a separate copy of the rubric for each site.)</p>

          <div className="flex flex-wrap md:flex-nowrap justify-between">
            <Button
              action="secondary"
              className="mt-2 md:mt-0"
              color="purple"
              icon="external"
              href="/article/cbd/cbd-now"
              title="CBD website 1"
            >
              CBD website 1
            </Button>
            <Button
              action="secondary"
              className="mt-2 md:mt-0 md:mx-2"
              color="purple"
              icon="external"
              href="/article/cbd/cbd-truth"
              title="CBD website 2"
            >
              CBD website 2
            </Button>
            <Button
              action="secondary"
              className="mt-2 md:mt-0"
              color="purple"
              icon="external"
              href="/article/cbd/cansativa-organics"
              title="CBD website 3"
            >
              CBD website 3
            </Button>
          </div>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
